<template>
  <Suspense>
    <navbar-admin />
  </Suspense>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="mx-auto col-lg-6 col-12">

        <div class="card">
          <div class="card-header pb-0 p-3">
            <h6 class="mb-0">Widget Setup</h6>
          </div>
          <div class="card-body p-3">
            <form role="form" @submit.prevent="handleSubmit" class="text-start">
              <div class="form-group d-flex align-items-center justify-content-between">

                <span class="text-sm">Display of default articles</span>
                <div class="form-check form-switch">
                  <input id="default-display-articles" class="form-check-input" type="checkbox" data-icon="ni ni-bell-55"
                    v-model="default_display_articles" />
                  <label class="form-check-label" for="default-display-articles"></label>
                </div>
              </div>
              <div class="row mt-5">
                <div class="col-lg-8 col-12 actions text-end ms-auto">
                  <argon-button color="success" variant="outline" size="sm" class="btn-sm mb-0 me-1"
                    type="reset">Cancel</argon-button>
                  <argon-button color="success" variant="gradient" size="sm" class="btn-sm mb-0">Save
                    Changes</argon-button>
                </div>
              </div>
            </form>
          </div>
        </div>

      </div>
    </div>
  </div>
  <app-footer />
</template>
<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import NavbarAdmin from "@/components/custom/admin/NavbarAdmin.vue";
import AppFooter from "@/components/custom/Footer.vue";
import setNavPills from "@/assets/js/nav-pills.js";
import ArgonButton from "@/components/ArgonButton.vue";
import { projectFirestore, timestamp } from '@/firebase/config'
import { useStore } from 'vuex';

export default {
  name: "Setup",

  components: {
    /* ArgonSwitch, */
    NavbarAdmin,
    AppFooter,
    ArgonButton,
  },
  setup() {
    const default_display_articles = ref('')

    const user = ref(localStorage.getItem('user_id'))

    /* var libdomain = 'app.leadconnector.co';
    projectFirestore.collection("SupportLibraries").where("library_domain", "==", libdomain)
    .get()
    .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, " => ", doc.data());
        });
    })
    .catch((error) => {
        console.log("Error getting documents: ", error);
    }); */
    const store = useStore()
    var libid = store.state.parent_lib_id;
    if (libid) {
      projectFirestore.collection("Users").where("support_lib_id", "==", libid)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            console.log("User ID: ", doc.id, " => ", doc.data());
          });
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    }


    const router = useRouter()

    const handleSubmit = async () => {
      const setting = {
        default_display: default_display_articles.value
      }
      const res = await projectFirestore.collection('SupportLibraries').doc(user.value).set(setting)
      console.log("Response: ", res) // can see the id and path of doc created
      router.push({ name: 'Articles' })
    }

    return { default_display_articles, handleSubmit }
  },
  data() {
    return {};
  },
  mounted() {
    this.$store.state.showSidenav = false;
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = false;
    setNavPills();
  },
  beforeUnmount() {
    this.$store.state.showSidenav = true;
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    if (this.$store.state.isPinned === false) {
      const sidenav_show = document.querySelector(".g-sidenav-show");
      sidenav_show.classList.remove("g-sidenav-hidden");
      sidenav_show.classList.add("g-sidenav-pinned");
      this.$store.state.isPinned = true;
    }
  },

};
</script>
