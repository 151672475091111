<template>
  <div>
    <HomeSelectSection v-if="step == 'HELP_WITH'" :title="contactFormData?.qualifyUserQuestion?.question"
      :sub-title="contactFormData?.qualifyUserQuestion?.helpText"
      :data-source="contactFormData?.qualifyUserQuestion?.answers" @on-select="handleSelectHelpWith" />


    <HomeSelectItem v-if="step !== 'HELP_WITH'" :is-submit="isSubmit" :text="helpWithText"
      @on-back="handleStepBack('helpWithText')" />

    <HomeSelectItem v-if="Object.keys(loginIssueText).length" :is-submit="isSubmit" :text="loginIssueText"
      @on-back="handleStepBack('loginIssueText')" />

    <HomeSelectItem v-if="Object.keys(notFoundIssueText).length" :is-submit="isSubmit" :text="notFoundIssueText"
      @on-back="handleStepBack('notFoundIssueText')" />

    <HomeSelectItem v-if="Object.keys(issueText).length" :is-submit="isSubmit" :text="issueText"
      @on-back="handleStepBack('issueText')" />



    <HomeSelectSection v-if="step == 'ISSUES'"
      :title="contactFormData?.steps?.filter((el => el.stepSelected == 'ISSUES'))[0].contactFormQuestionData?.question"
      :sub-title="contactFormData?.steps?.filter((el => el.stepSelected == 'ISSUES'))[0].contactFormQuestionData?.helpText"
      :data-source="contactFormData?.steps?.filter((el => el.stepSelected == 'ISSUES'))[0].contactFormQuestionData?.answers || []"
      @on-select="handleSelectIssue" />


    <HomeSelectSection v-if="step == 'LOGIN_ISSUE'"
      :title="contactFormData?.steps?.filter((el => el.stepSelected == 'LOGIN_ISSUE'))[0].contactFormQuestionData?.question"
      :data-source="contactFormData?.steps?.filter((el => el.stepSelected == 'LOGIN_ISSUE'))[0].contactFormQuestionData?.answers || []"
      @on-select="handleSelectLoginIssue" />

    <HomeSelectSection v-if="step == 'SUB_HELP_WITH'"
      :title="contactFormData?.steps?.filter((el => el.stepSelected == 'HELP_WITH'))[0].contactFormQuestionData?.question"
      :sub-title="contactFormData?.steps?.filter((el => el.stepSelected == 'HELP_WITH'))[0].contactFormQuestionData?.helpText"
      :data-source="contactFormData?.steps?.filter((el => el.stepSelected == 'HELP_WITH'))[0].contactFormQuestionData?.answers || []"
      @on-select="handleSelectHelpWith" />



    <HomeHelpLibrary :failedSearches="failedSearches" v-if="step == 'CHECK_HELP_LIBRARY'"
      :data-source="contactFormData?.searchStep" :set-parent-view="setViewMode" @on-clear="handleStepBack" @on-back="notFoundIssueText = $event, helpSteps.push(
        {
          title: $event?.title,
          answer: $event?.answer
        }
      ); contactFormData.steps.length ? step = 'ISSUES' : step = 'FINAL_STEP'" />

    <HomeFinalStep v-if="step == 'FINAL_STEP'" :stepData="contactFormData?.formSubmission" :is-submit="isSubmit"
      @on-submit="handleSubmit" @on-back="handleStepBack" />
  </div>
</template>

<script>
import HomeSelectSection from './HomeSelectSection';
import HomeSelectItem from './HomeSelectItem';
import HomeHelpLibrary from './HomeHelpLibrary';
import HomeFinalStep from './HomeFinalStep';
import { sendSupportContactEmail } from '@/composables/sendSupportContactEmail';
import useStorage from '@/composables/useStorage';
import { useStore } from "vuex";
import { useRoute } from 'vue-router';
import { projectFirestore, timestamp } from '@/firebase/config'
import { ref } from 'vue';
import getUser from "@/composables/getUser"

export default {
  components: {
    HomeSelectSection,
    HomeSelectItem,
    HomeHelpLibrary,
    HomeFinalStep,
  },
  props: {
    contactFormData: {
      type: Object,
      default: {}
    },
    setViewMode: {
      type: Function,
      default: () => { }
    },
    failedSearches: {
      type: String,
      default: null
    },
    parentURL: {
      type: String,
      default:null
    }
  },
  setup() {
    const store = useStore();
    const { user } = getUser();
    const route = useRoute();
    const companyName = ref('');
    const companyEmail = ref('');
    const companyPhone = ref('');
    const selfManaged = ref(false)
    projectFirestore.collection('SupportLibraries').doc(route.params.library_key).get().then(async (snap) => {
      if (snap.exists) {
        if (snap.data().settings && snap.data().settings?.settings?.companyName) {
          companyName.value = snap.data().settings?.settings?.companyName
          companyEmail.value = snap.data().settings?.settings?.companyEmail,
            companyPhone.value = snap.data().settings?.settings?.companyPhone
        }
        if (snap.data() && snap.data().settings?.ticket) {
          selfManaged.value = snap.data().settings?.ticket?.selfManaged
        }
      }
    })
    return {
      store,
      route,
      companyName,
      companyEmail,
      companyPhone,
      selfManaged,
      user
    }
  },
  data() {
    return {
      step: 'HELP_WITH',
      helpWith: this.contactFormData?.qualifyUserQuestion?.answers,
      //issues: [
      //   'An automation 🤖',
      //   'A calendar 📆',
      //   'Contacts 📱',
      //   'Funnel or Website 💻',
      //   'DNS and Domains 📶',
      //   'SMTP or Email 📨',
      //   'SMS and Voice 📞',
      //   'Social Media 🤳',
      //   "Other / I don't see my topic listed ❓",
      // ],
      //loginIssues:[
      //   "I can't log into the platform with my One Time Password or 2 Factor Authentication",
      //   "I can't access the training videos",
      //   "It's something else",
      // ],
      helpWithIndex: null,
      helpWithText: {},
      issueText: {},
      loginIssueText: {},
      isSubmit: false,
      notFoundIssueText: {},
      helpSteps: []
    };
  },
  computed: {
    // step() {
    //   if (this.helpWithIndex === null) {
    //     return HELP_WITH;
    //   }
    //   if (this.helpWithIndex === 0) {
    //     if (!this.issueText) {
    //       return ISSUES;
    //     }
    //     return FINAL_STEP;
    //   }
    //   if (this.helpWithIndex === 1) {
    //     return CHECK_HELP_LIBRARY;
    //   }
    //   if (this.helpWithIndex === 2) {
    //     return FINAL_STEP;
    //   }
    //   if (this.helpWithIndex === 3) {
    //     if (!this.loginIssueText) {
    //       return LOGIN_ISSUE;
    //     }
    //     return FINAL_STEP;
    //   }

    //   return null;
    // },
  },
  methods: {
    init() {
      this.helpWithIndex = null;
      this.helpWithText = '';
      this.issueText = '';
      this.loginIssueText = '';
      this.helpSteps = []
    },
    handleSelectHelpWith({ item, index }) {
      console.log('item', item)
      if (this.helpSteps.length == 0) {
        this.helpSteps.push(
          {
            title: this.contactFormData?.qualifyUserQuestion?.question,
            answer: item.answer
          }
        )
      }
      console.log('this.helpSteps', this.helpSteps)
      this.helpWithText = item;
      this.helpWithIndex = index;
      if (item?.search) {
        this.step = 'CHECK_HELP_LIBRARY'
      } else {
        this.step = item.goTo;
      }
      // console.log('handleSelectHelpWith :-', item)
    },
    handleBackToHelpWith() {
      this.step = 'HELP_WITH';
      this.init();
    },
    handleSelectIssue({ item }) {
      console.log(item)
      this.helpSteps.push(
        {
          title: this.contactFormData?.steps?.filter((el => el.stepSelected == 'ISSUES'))[0].contactFormQuestionData?.question,
          answer: item.answer
        }
      )
      // console.log('handleSelectHelpWith :-', item)
      this.issueText = item;
      if (item.goTo == 'HELP_WITH') {
        if (this.contactFormData.steps.length) {
          item.goTo = 'SUB_HELP_WITH'
        } else {
          item.goTo = 'FINAL_STEP'
        }
      }
      this.step = item.goTo;
    },
    handleSelectLoginIssue({ item }) {
      console.log('item', item)
      this.helpSteps.push(
        {
          title: this.contactFormData?.steps?.filter((el => el.stepSelected == 'LOGIN_ISSUE'))[0].contactFormQuestionData?.question,
          answer: item.answer
        }
      )
      console.log('this.helpSteps', this.helpSteps)
      this.loginIssueText = item;
      this.step = 'FINAL_STEP';
    },

    handleBackToIssue() {
      this.issueText = {};
      this.step = 'ISSUES'
    },
    handleBackToLoginIssue() {
      this.loginIssueText = {};
    },

    handleStepBack(event) {
      console.log('this.helpSteps', this.helpSteps)
      this.helpSteps.pop()
      this.$store.dispatch('updateLastSuccessArticleSearch', { data: '' })
      console.log('event')
      if (event) {
        if (event == 'issueText') {
          this.issueText = {};
          if (this.contactFormData.steps.length) {
            this.step = 'ISSUES'
          } else {
            this.step = 'FINAL_STEP'
          }
        }
        if (event == 'loginIssueText') {
          this.loginIssueText = {};
          if (this.contactFormData.steps.length) {
            this.step = 'LOGIN_ISSUE'
          } else {
            this.step = 'FINAL_STEP'
          }
        }
        if (event == 'helpWithText') {
          this.helpWithText = {};
            this.step = 'HELP_WITH'
        }
        if (event == 'notFoundIssueText') {
          this.notFoundIssueText = {};
          this.step = 'CHECK_HELP_LIBRARY'
        }
      }
      else {
        this.helpWithText = {}
        this.issueText = {};
        this.loginIssueText = {};
        this.notFoundIssueText = {};
        this.step = 'HELP_WITH'
      }

    },

    async handleSubmit(form) {
      // console.log(this.helpSteps);
      let lastArticleId = this.$store.getters.getLastFailedArticleSearch?.id;
      let searchkeyword = this.$store.getters.getSearchedKeyword?.query ? this.$store.getters.getSearchedKeyword?.query : '';
      // console.log('this.$store.getters.getSearchedKeyword.query', this.$store.getters.getSearchedKeyword);
      let support_lib_id = this.$route.params.library_key;
      let userAgent = window.navigator.userAgent
      let platform = window.navigator.platform
      let macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
      let windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
      let iosPlatforms = ['iPhone', 'iPad', 'iPod']
      let os = null;
      let browser = null;

      if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'Mac OS';
      } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'iOS';
      } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Windows';
      } else if (/Android/.test(userAgent)) {
        os = 'Android';
      } else if (!os && /Linux/.test(platform)) {
        os = 'Linux';
      }

      if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
        browser = 'Opera';
      } else if (navigator.userAgent.indexOf("Edg") != -1) {
        browser = 'Edge';
      } else if (navigator.userAgent.indexOf("Chrome") != -1) {
        browser = 'Chrome';
      } else if (navigator.userAgent.indexOf("Safari") != -1) {
        browser = 'Safari';
      } else if (navigator.userAgent.indexOf("Firefox") != -1) {
        browser = 'Firefox';
      } else if ((navigator.userAgent.indexOf("MSIE") != -1) || (!!document.documentMode == true)) {
        browser = 'IE';
      } else {
        browser = 'unknown';
      }

      let ticketLocationUrl = '';
      if(this.parentURL) {
        ticketLocationUrl = this.parentURL
      } else {
        if (window.location != window.parent.location) {
          let uri = new URL(window.location.href)
          let params = new URLSearchParams(uri.search);
          if (params.get("parentURL")) {
            ticketLocationUrl = params.get("parentURL");
          } else {
            ticketLocationUrl = document.referrer
          }

        } else {
          ticketLocationUrl = document.location.href
        }
      }

      //  console.log(ticketLocationUrl)
      // return
      // console.log('window.frameElement.src', URL )

      const { uploadImage, url, error } = useStorage();
      const { email, request, file, customInputs } = form;
      if (file) {
        await uploadImage(file);
      }



      if (error.value) {
        console.error(error.message);
      }


      try {
        sendSupportContactEmail(
          email,
          this.companyName,
          this.companyEmail,
          this.companyPhone,
          this.selfManaged,
          searchkeyword,
          request,
          this.helpWithText,
          url.value,
          lastArticleId,
          support_lib_id,
          os,
          browser,
          ticketLocationUrl,
          this.helpSteps,
          customInputs
        );
        this.isSubmit = true;

        this.$store.dispatch('updateLastFailedArticleSearch', { data: '' })
      } catch (error) {
        console.error(error);
      }
    }
  },
  mounted() {
    // console.log('this.contactFormData :- ', this.contactFormData)

  },
  watch: {
    helpSteps: function () {
      // console.log('this.helpSteps', this.helpSteps)
    }
  }
};
</script>

<style>

</style>
