import { createRouter, createWebHistory } from 'vue-router';
import { projectAuth } from '@/firebase/config';
import Setup from '@/views/admin/Setup.vue';
import Hlpt from '@/views/Hlpt.vue';
import Login from '@/views/Login.vue';
// import Welcome from '@/views/Welcome.vue';
// import Home2 from '@/views/Home2.vue';
// import Admin from '@/views/admin/Admin';
// import Content from '@/views/admin/Content.vue';
// import EditArticle from '@/views/admin/EditArticle.vue';
// import Articles from '@/views/admin/Articles.vue';
// import CreateArticle from '@/views/admin/CreateArticle.vue';
// import CreateNewsFlash from '@/views/admin/CreateNewsFlash.vue';
// import EditNewsFlash from '@/views/admin/EditNewsFlash.vue';
// import Categories from '@/views/admin/Categories.vue';
// import ContactForm from '@/views/admin/ContactForm';

// import Statistics from '@/views/admin/Statistics/Statistics';
// import Insights from '@/views/admin/Statistics/Insights';
// import StatsContent from '@/views/admin/Statistics/StatsContent';
// import FailedSearches from '@/views/admin/Statistics/FailedSearches';
// import DeflectedTickets from '@/views/admin/Statistics/DeflectedTickets';
// import NewsFlashReports from '@/views/admin/Statistics/NewsFlashReports';
// import Keywords from '@/views/admin/Statistics/Keywords';
// import Tickets from '@/views/admin/Statistics/Tickets';
// import Tasks from '@/views/admin/Statistics/Tasks';
// import Stats from '@/views/admin/Statistics/Stats';
// import Chats from '@/views/admin/Statistics/Chats';

//import NewsFlash from '@/views/admin/NewsFlash';
// import Account from '@/views/admin/Profile/Account';
// import Profile from '@/views/admin/Profile/Profile';
// import Settings from '@/views/admin/Profile/Settings';
// import Theme from '@/views/admin/Profile/Theme';
// import ChangePassword from '@/views/admin/Profile/ChangePassword';
// import Team from '@/views/admin/Profile/Team';
// import Integrations from '@/views/admin/Profile/Integrations';
// import Language from '@/views/admin/Profile/Language';
// import Contact from '@/views/admin/Contact.vue';

// import Frontend from '@/views/frontend/Frontend.vue';
// import HubHome from '@/views/frontend/HubHome.vue';
// import ArticleDetails from '@/views/frontend/ArticleDetails.vue';
// import ArticleCategoriesDetails from '@/views/frontend/ArticleCategoriesDetails';
// import FrontendNews from '@/views/frontend/FrontendNews.vue';
// import FrontendNewsFlash from '@/views/frontend/FrontendNewsFlash.vue';

// import AdvancedWidget from '@/views/widget/widgetAdvanced/AdvancedWidget';
import HomeAdvancedDashboard from '@/views/widget/widgetAdvanced/HomeAdvancedDashboard';
// import HomeAdvancedChat from '@/views/widget/widgetAdvanced/HomeAdvancedChat';
// import HomeAdvancedMsg from '@/views/widget/widgetAdvanced/HomeAdvancedMsg';
// import HomeAdvancedHelp from '@/views/widget/widgetAdvanced/HomeAdvancedHelp';
// import HomeAdvancedNews from '@/views/widget/widgetAdvanced/HomeAdvancedNews';
// import HomeAdvancedArticles from '@/views/widget/widgetAdvanced/HomeAdvancedArticles';
// import HomeAdvancedNewsFlash from '@/views/widget/widgetAdvanced/HomeAdvancedNewsFlash';
import Home from '@/views/widget/widgetClassic/Home.vue';
// import HomeAdvanced from '@/views/widget/widgetAdvanced/HomeAdvanced.vue';

import Error404 from '@/views/Error/Error404';
import ResetPassword from '@/views/ResetPassword';
import Invite from '@/views/Invite';
import Preview from '@/views/admin/Preview';

import Forgot from '@/views/Forgot';

import DefaultIntegrations from '@/views/DefaultIntegrations';
import Thankyou from '@/views/Thankyou';
import store from '../store';
import ScreenShare from '@/views/ScreenShare.vue';

function lazyLoad(view) {
  return () => import(`@/views/${view}.vue`);
}
function lazyLoadHub(view) {
  return () => import(`@/views/frontend/${view}.vue`);
}

function lazyLoadAdminProfile(view) {
  return () => import(`@/views/admin/Profile/${view}.vue`);
}

function lazyLoadAdminStats(view) {
  return () => import(`@/views/admin/Statistics/${view}.vue`);
}

function lazyLoadAdmin(view) {
  return () => import(`@/views/admin/${view}.vue`);
}

function lazyLoadAdminSop(view) {
  return () => import(`@/views/admin/Sop/${view}.vue`);
}

function lazyLoadAdminAutomation(view) {
  return () => import(`@/views/admin/Automation/${view}.vue`);
}
function lazyLoadAdminFormbuilder(view) {
  return () => import(`@/views/admin/Formbuilder/${view}.vue`);
}
function lazyLoadAdminCourses(view) {
  return () => import(`@/views/admin/Courses/${view}.vue`);
}

function lazyLoadAdvancedWidget(view) {
  return () => import(`@/views/widget/widgetAdvanced/${view}.vue`);
}

function lazyLoadChat(view) {
  return () => import(`@/views/admin/Chat/${view}.vue`);
}

const requireAuth = (to, from, next) => {
  let style = document.getElementById('customWidgetCss');
  let user = projectAuth.currentUser;
  // console.log('current user in auth guard: ', user)
  if (!user) {
    next('/');
  } else {
    // console.log('from', to)
    const userPermissions = store.state.userPermissions;
    if(to.name == "Automation" && userPermissions?.canCreateAndEditAnAutomation == false) {
      next({name:'AccessDenied'});
    } else if (to.name == "Formbuilder" && userPermissions?.canCreateAndEditFormbuilder == false) {
      next({ name: 'AccessDenied' });
    } else if(['Notifications', 'TicketSettings','TicketType', 'TicketCategories', 'TicketTiers'].includes(to.name) && !userPermissions?.canManageWidgetSettings) {
      next({name:'AccessDenied'});
    } else if(to.name == 'Settings' && !userPermissions?.canManageGeneralAndSecuritySettings) {
      next({name:'Account'});
    } else if(['Team', 'TeammatesActivityLogs', 'TeammatePermissions'].includes(to.name) && !userPermissions?.canManageTeammatesSeatsAndPermissions)  {
      next({name:'AccessDenied'});
    } else if(['Content', 'EditArticle', 'CreateArticle'].includes(to.name) && !userPermissions?.canCreateAndUpdateHelpCenterArticles) {
      next({name:'AccessDenied'});
    } else if(['NewsFlash', 'EditNewsFlash', 'CreateNewsFlash'].includes(to.name) && !userPermissions.canCreateAndUpdateNewsArticles) {
      next({name:'AccessDenied'});
    } else if(['Courses', 'UpdateCourse', 'CreateCourse'].includes(to.name) && !userPermissions.canCreateAndUpdateCoursesArticles) {
      next({name:'AccessDenied'});
    } else if(['Sop', 'updateSop', 'CreateSop'].includes(to.name) && !userPermissions.canCreateAndUpdateSopArticles) {
      next({name:'AccessDenied'});
    } else if(to.name == 'ChatInboxClone' && to?.query?.tab == 'queue' && !userPermissions.canTakeChats) {
      next({name:'AccessDenied'});
    } else if(to.name == 'ChatInboxClone' && to?.query?.tab == 'chats' && !userPermissions.canSuperviseChats) {
      next({name:'AccessDenied'});
    } else if(to.name == 'ChatInboxClone' && to?.query?.tab == 'archives' && !userPermissions.canViewChatArchives) {
      next({name:'AccessDenied'});
    } else if(to.name == 'ChatInboxClone' && ['tickets', 'othertickets', 'mentiontickets', 'createdbyyoutickets', 'awaitingreplytickets', 'hlpttickets', 'awaitingreplyhlpttickets'].includes(to?.query?.tab) && !userPermissions.canViewExistingTickets) {
      next({name:'AccessDenied'});
    } else {
      next();
    }
   
  }
  if (style) {
    style.innerHTML = '';
  }
};

const routes = [
  {
    path: '/defaultIntegrations/:id',
    name: 'DefaultIntegrations',
    //redirect: "/dashboards/landing"
    component: DefaultIntegrations,
  },
  // hub page routes
  {
    path: '/',
    name: 'Frontend',
    component: lazyLoadHub('Frontend'),
    children: [
      {
        path: '',
        name: 'HubHome2',
        component: lazyLoadHub('HubHome'),
      },
      {
        path: ':library_key',
        name: 'HubHome',
        component: lazyLoadHub('HubHome'),
      },
      {
        path: 'courses',
        name: 'HubCourses2',
        component: lazyLoadHub('HubCourses'),
      },
      {
        path: ':library_key/courses',
        name: 'HubCourses',
        component: lazyLoadHub('HubCourses'),
      },
      {
        path: 'course/:library_key/:id',
        name: 'HubCourseDetails',
        component: lazyLoadHub('HubCourseDetails'),
      },

      {
        path: 'coursestatic/:library_key/:id',
        name: 'HubCourseDetailsStatic',
        component: lazyLoadHub('HubCourseDetails2'),
      },

      {
        path: 'course/:id',
        name: 'HubCourseDetails2',
        component: lazyLoadHub('HubCourseDetails'),
      },

      // {
      //   path: "articlecategories/",
      //   name: "ArticleCategories",
      //   component: ArticleCategories
      // },
      {
        path: 'frontendnewsflash/:library_key/:id',
        name: 'FrontendNewsFlash',
        component: lazyLoadHub('FrontendNewsFlash'),
      },
      {
        path: 'frontendnews/:library_key',
        name: 'FrontendNews',
        component: lazyLoadHub('FrontendNews'),
      },
      {
        path: 'article/details/:library_key/:id',
        name: 'ArticleDetails',
        component: lazyLoadHub('ArticleDetails'),
      },

      {
        path: 'article/details/:id',
        name: 'ArticleDetails2',
        component: lazyLoadHub('ArticleDetails'),
      },
      // {
      //   path: "/articlecategories/",
      //   name: "ArticleCategories",
      //   component: ArticleCategories
      // },
      // {
      //   path: "/article/details/:library_key/:id",
      //   name: "ArticleDetails",
      //   //redirect: "/dashboards/landing"
      //   component: ArticleDetails
      // },
      {
        path: 'articleCategoriesDetails/:library_key/:id',
        name: 'ArticleCategoriesDetails',
        component: lazyLoadHub('ArticleCategoriesDetails'),
      },
      {
        path: 'articleCategoriesDetails/:id',
        name: 'ArticleCategoriesDetails2',
        component: lazyLoadHub('ArticleCategoriesDetails'),
      },
    ],
  },

  //classic widget routes
  {
    path: '/widget/:library_key/',
    name: 'Home',
    component: Home,
  },

  //advanced widget routes
  {
    path: '/widgetAdvanced/:library_key/',
    name: 'HomeAdvanced',
    component: lazyLoadAdvancedWidget('HomeAdvanced'),
  },

  {
    path: '/advancedWidget/:library_key/',
    name: 'AdvancedWidget',
    component: lazyLoadAdvancedWidget('AdvancedWidget'),
    children: [
      {
        path: '',
        name: 'HomeAdvancedDashboardIndex',
        redirect: HomeAdvancedDashboard,
      },
      {
        path: 'dashboard',
        name: 'HomeAdvancedDashboard',
        component: lazyLoadAdvancedWidget('HomeAdvancedDashboard'),
      },
      {
        path: 'chat',
        name: 'HomeAdvancedChat',
        component: lazyLoadAdvancedWidget('HomeAdvancedChat'),
      },
      {
        path: 'chatmodal',
        name: 'HomeAdvancedModalChat',
        component: lazyLoadAdvancedWidget('HomeAdvancedModalChat'),
      },

      {
        path: 'message',
        name: 'HomeAdvancedMsg',
        component: lazyLoadAdvancedWidget('HomeAdvancedMsg'),
      },
      {
        path: 'help',
        name: 'HomeAdvancedHelp',
        component: lazyLoadAdvancedWidget('HomeAdvancedHelp'),
      },
      {
        path: 'help/:categoryId',
        name: 'HomeAdvancedHelp2',
        component: lazyLoadAdvancedWidget('HomeAdvancedHelp'),
      },
      {
        path: 'news',
        name: 'HomeAdvancedNews',
        component: lazyLoadAdvancedWidget('HomeAdvancedNews'),
      },
      {
        path: 'customlink/:name/:id',
        name: 'HomeAdvancedCustomLink',
        component: lazyLoadAdvancedWidget('HomeAdvancedCustomLink'),
      },
      {
        path: 'articleDetail/:id',
        name: 'HomeAdvancedArticles',
        component: lazyLoadAdvancedWidget('HomeAdvancedArticles'),
      },
      {
        path: 'articleDetail/:id/:successArticleSearchId',
        name: 'HomeAdvancedArticlesSearched',
        component: lazyLoadAdvancedWidget('HomeAdvancedArticles'),
      },
      {
        path: 'newsFlashDetail/:id',
        name: 'HomeAdvancedNewsFlash',
        component: lazyLoadAdvancedWidget('HomeAdvancedNewsFlash'),
      },
    ],
  },
  // {
  //   path: '/home2',
  //   name: 'Home2',
  //   //redirect: "/dashboards/landing"
  //   component: Home2,
  // },
  {
    path: '/login',
    name: 'Login',
    //redirect: "/dashboards/landing"
    component: Login,
  },
  {
    path:'/screenshare',
    name:'ScreenShare',
    component:ScreenShare,
  },
  {
    path: '/forgot',
    name: 'Forgot',
    component: Forgot,
  },

  //admin routes

  {
    path: '/google_auth',
    name: 'GoogleAuth',
    component: lazyLoad('GoogleAuth'),
  },
  {
    path: '/admin',
    name: 'Admin',
    component: lazyLoadAdmin('Admin'),
    beforeEnter: requireAuth,
    children: [
      {
        path: '',
        name: 'AdminIndex',
        redirect: 'admin/content',
      },
      {
        path:'accessdenied',
        name: 'AccessDenied',
        component: lazyLoadAdmin('AccessDenied'),
      },
      {
        path: 'content',
        name: 'Content',
        component: lazyLoadAdmin('Content'),
        beforeEnter: requireAuth,
      },
      {
        path: 'chatinbox',
        name: 'ChatInbox',
        component: lazyLoadAdmin('ChatInbox'),
      },
      {
        path: 'sop',
        name: 'Sop',
        component: lazyLoadAdminSop('Sop'),
      },
      {
        path: 'sop/create',
        name: 'CreateSop',
        component: lazyLoadAdminSop('ManageSop'),
      },
      {
        path: 'sop/update/:id',
        name: 'updateSop',
        component: lazyLoadAdminSop('ManageSop'),
      },
      
      {
        path: 'courses',
        name: 'Courses',
        component: lazyLoadAdminCourses('Courses'),
      },
      {
        path: 'automation',
        name: 'Automation',
        component: lazyLoadAdminAutomation('Automation'),
      },
      {
        path: 'manageautomation/:automation_ref',
        name: 'ManageAutomation',
        component: lazyLoadAdminAutomation('ManageAutomation'),
      },
      {
        path: 'formbuilder',
        name: 'Formbuilder',
        component: lazyLoadAdminFormbuilder('Formbuilder'),
      },
      {
        path: 'manageformbuilder',
        name: 'ManageFormbuilder',
        component: lazyLoadAdminFormbuilder('ManageFormbuilder'),
      },
      {
        path: 'courses/create/:course_id',
        name: 'CreateCourse',
        component: lazyLoadAdminCourses('ManageCourses'),
      },
      {
        path: 'courses/update/:id/:course_id',
        name: 'UpdateCourse',
        component: lazyLoadAdminCourses('ManageCourses'),
      },
      {
        path: 'inbox',
        name: 'ChatInboxClone',
        component: lazyLoadChat('ChatInboxClone'),
      },

      {
        path: 'newsflash',
        name: 'NewsFlash',
        component: lazyLoadAdmin('NewsFlash'),
        beforeEnter: requireAuth,
      },
      {
        path: 'newsflash/create',
        name: 'CreateNewsFlash',
        component: lazyLoadAdmin('CreateNewsFlash'),
        beforeEnter: requireAuth,
      },
      {
        path: 'newsflash/edit/:id',
        name: 'EditNewsFlash',
        component: lazyLoadAdmin('EditNewsFlash'),
        beforeEnter: requireAuth,
      },
      {
        path: 'preview',
        name: 'Preview',
        component: lazyLoadAdmin('Preview'),
        beforeEnter: requireAuth,
      },
      {
        path: 'article/edit/:id',
        name: 'EditArticle',
        component: lazyLoadAdmin('EditArticle'),
        beforeEnter: requireAuth,
      },
      {
        path: 'article/create',
        name: 'CreateArticle',
        component: lazyLoadAdmin('CreateArticle'),
        beforeEnter: requireAuth,
      },
      {
        path: 'contactform',
        name: 'ContactForm',
        component: lazyLoadAdmin('ContactForm'),
        beforeEnter: requireAuth,
      },
      {
        path: 'statistic',
        component: lazyLoadAdminStats('Stats'),
        name: 'Stats',
        beforeEnter: requireAuth,
        children: [
          {
            path: '',
            name: 'statsIndex',
            redirect: '/admin/statistic/dashboard',
          },
          {
            path: 'dashboard',
            name: 'Statistics',
            component: lazyLoadAdminStats('Statistics'),
          },
          {
            path: 'dashboardnew',
            name: 'DashboardNew',
            component: lazyLoadAdmin('DashboardNew'),
          },
          {
            path: 'insights',
            name: 'Insights',
            component: lazyLoadAdminStats('Insights'),
          },
          {
            path: 'content',
            name: 'StatsContent',
            component: lazyLoadAdminStats('StatsContent'),
          },
          {
            path: 'failedSearches',
            name: 'FailedSearches',
            component: lazyLoadAdminStats('FailedSearches'),
          },
          {
            path: 'deflectedTickets',
            name: 'DeflectedTickets',
            component: lazyLoadAdminStats('DeflectedTickets'),
          },
          {
            path: 'tickets',
            name: 'StatsTickets',
            component: lazyLoadAdminStats('Tickets'),
          },
          {
            path: 'keywords',
            name: 'Keywords',
            component: lazyLoadAdminStats('Keywords'),
          },
          {
            path: 'tasks',
            name: 'Tasks',
            component: lazyLoadAdminStats('Tasks'),
          },
          {
            path: 'chats',
            name: 'Chats',
            component: lazyLoadAdminStats('Chats'),
          },
          {
            path: 'newsFlashReports',
            name: 'NewsFlashReports',
            component: lazyLoadAdminStats('NewsFlashReports'),
          },
        ],
      },
      {
        path: 'profile',
        component: lazyLoadAdminProfile('Profile'),
        name: 'Profile',
        beforeEnter: requireAuth,
        children: [
          {
            path: '',
            name: 'ProfileIndex',
            redirect: '/admin/profile/account',
          },
          {
            path: 'account',
            name: 'Account',
            component: lazyLoadAdminProfile('Account'),
            beforeEnter: requireAuth,
          },
          {
            path: 'settings',
            name: 'Settings',
            component: lazyLoadAdminProfile('Settings'),
            beforeEnter: requireAuth,
          },
          {
            path: 'theme',
            name: 'Theme',
            component: lazyLoadAdminProfile('Theme'),
            beforeEnter: requireAuth,
          },
          {
            path: 'tickets',
            name: 'Tickets',
            component: lazyLoadAdminProfile('Tickets'),
            beforeEnter: requireAuth,
          },
          {
            path: 'changePassword',
            name: 'ChangePassword',
            component: lazyLoadAdminProfile('ChangePassword'),
            beforeEnter: requireAuth,
          },
          {
            path: 'teammates',
            name: 'Team',
            component: lazyLoadAdminProfile('Team'),
            beforeEnter: requireAuth,
          },
          {
            path: 'teamlogs',
            name: 'TeammatesActivityLogs',
            component: lazyLoadAdminProfile('TeammatesActivityLogs'),
            beforeEnter: requireAuth,
          },
          {
            path: 'permissions',
            name: 'TeammatePermissions',
            component: lazyLoadAdminProfile('TeammatePermissions'),
            beforeEnter: requireAuth,
          },
          
          {
            path: 'integrations',
            name: 'Integrations',
            component: lazyLoadAdminProfile('Integrations'),
            beforeEnter: requireAuth,
          },
          {
            path: 'ticketsettings',
            name: 'TicketSettings',
            component: lazyLoadAdminProfile('TicketSettings'),
            beforeEnter: requireAuth,
          },
          {
            path: 'tickettypes',
            name: 'TicketTypes',
            component: lazyLoadAdminProfile('TicketTypes'),
            beforeEnter: requireAuth,
          },
          {
            path: 'notifications',
            name: 'Notifications',
            component: lazyLoadAdminProfile('Notifications'),
            beforeEnter: requireAuth,
          },
          {
            path: 'ticketcategories',
            name: 'TicketCategories',
            component: lazyLoadAdminProfile('TicketCategories'),
            beforeEnter: requireAuth,
          },
          {
            path: 'tickettiers',
            name: 'TicketTiers',
            component: lazyLoadAdminProfile('TicketTiers'),
            beforeEnter: requireAuth,
          },
          {
            path: 'language',
            name: 'Language',
            component: lazyLoadAdminProfile('Language'),
            beforeEnter: requireAuth,
          },
        ],
      },
    ],
  },

  {
    path: '/contact',
    name: 'Contact',
    component: lazyLoadAdmin('Contact'),
    beforeEnter: requireAuth,
  },
  {
    path: '/admin/setup',
    name: 'Setup',
    component: Setup,
    beforeEnter: requireAuth,
  },
  // {
  //   path: '/admin/articles',
  //   name: 'Articles',
  //   component: Articles,
  //   beforeEnter: requireAuth,
  // },
  // {
  //   path: '/admin/categories',
  //   name: 'Categories',
  //   component: Categories,
  //   beforeEnter: requireAuth,
  // },

  {
    path: '/Invite',
    name: 'Invite',
    component: Invite,
  },
  {
    path: '/resetpassword',
    name: 'ResetPassword',
    component: ResetPassword,
  },

  //ContactForm

  {
    path: '/:catchAll(.*)',
    name: 'Error404',
    component: Error404,
  },
  {
    path: '/hlpt',
    name: 'Hlpt',
    component: Hlpt,
  },
  {
    path: '/thank-you',
    name: 'Thankyou',
    component: Thankyou,
  },
];
let history = process.env.NODE_ENV !== 'EC2' ? process.env.BASE_URL : ''
const router = createRouter({
  history: createWebHistory(history),
  routes,
  linkActiveClass: 'active',
});

export default router;
