<template>
    <div>
        <iframe v-if="screenShareUrl" :src="screenShareUrl" class="w-100" style="height: 100vh;" frameborder="0"></iframe>
    </div>
</template>

<script>
    export default {
        name:'Screenshare',
        data() {
            return {
                screenShareUrl:''
            }
        },
        mounted() {
            this.screenShareUrl = JSON.parse(JSON.stringify(localStorage.getItem('screenshare_watch_link')))
            setTimeout(() => {
                localStorage.removeItem('screenshare_watch_link')
            }, 1000)
        }
    }
</script>